<template>
  <div>
    <ol>
      <li>
        <button type="button" class="btn btn-primary" @click="callwps">
          ①检测wps状态[外业]
        </button>
      </li>
      <li>
        <button type="button" class="btn btn-primary" @click="deployaddons">
          ②与wps握手[外业]
        </button>
      </li>
      <li>
        <button type="button" class="btn btn-primary" @click="openwps">
          模拟测试打开wps+登录[外业]
        </button>
      </li>
    </ol>
  </div>
</template>
<script>
import { Base64 } from "js-base64";
import { outdoorUrl } from "@/config.js";
export default {
  name: "OutdoorAdmin",
  methods: {
    // 调起wps
    callwps() {
      // window.location.href = "ksoWPSCloudSvr://start=RelayHttpServer"
      window.open("ksoWPSCloudSvr://start=RelayHttpServer", "_blank");
    },
    // 配置助手
    async deployaddons() {
      //"{"cmd":"enable/disable/disableall"","name":"wpsaddin/wpsaddin","url":"http://oa.bitbridge.top/","addonType":"et","online":"true"}"
      const data = {
        cmd: "enable",
        addonType: "et",
        name: "outdoor",
        url: outdoorUrl,
        online: "true",
      };
      const res = await fetch("http://localhost:58890/deployaddons/runParams", {
        method: "POST",
        body: Base64.encode(JSON.stringify(data)),
      });
      if (res.ok) {
        alert("配置成功");
      }
    },

    async openwps() {
      //step1
      const res1 = await fetch("http://127.0.0.1:58890/version", {
        method: "POST",
        body: "",
      });
      console.log(res1.ok); //结果为true,返回json响应,但是响应为1.0.0,无法转化为json,无法转化为 res.json()

      // 分支1
      if (!res1.ok) {
        const oafun = { testwps: { msg: "绿城南宁" } };
        const startInfo = {
          name: "outdoor",
          function: "dispatcher",
          info: JSON.stringify({ funcs: [oafun] }),
          jsPluginsXml: `${outdoorUrl}/jsplugins.xml`,
        };
        const strData = JSON.stringify(startInfo);
        const baseData = Base64.encode(strData);
        const data = "ksowebstartupet://" + baseData;
        const res = await fetch("http://127.0.0.1:58890/et/runParams", {
          method: "POST",
          body: data,
        });
        console.log(res);

        // 分支2
      } else {
        const oafun = {
          testwps: { msg: "绿城南宁", token: this.$store.state.auth.token },
        };
        // 发wps server 发送助手要调用的函数
        const cmdId = `js${new Date().getTime()}_0`;
        const url = "http://127.0.0.1:58890/transferEcho/runParams";
        const funcEx = "const res = dispatcher";
        const infocontent = JSON.stringify({ funcs: [oafun] });
        // 官方写法 const cbCode = `var xhr = new XMLHttpRequest();xhr.open('POST','${url}');xhr.send(JSON.stringify({id: '${cmdId}', response: res}));`;
        const cbCode = `fetch("${url}", {method: "POST",body: JSON.stringify({id: '${cmdId}', response: res})});`;
        const infoEx = `${infocontent});${cbCode}void(0`;
        const startInfo = {
          name: "outdoor",
          function: funcEx,
          info: infoEx,
          jsPluginsXml: `${outdoorUrl}/jsplugins.xml`,
        };
        const baseData = Base64.encode(JSON.stringify(startInfo));
        // 向wps server发请求
        const wraper = {
          id: cmdId,
          app: "et",
          data: `ksowebstartupet://${baseData}`,
        };
        const res = await fetch("http://127.0.0.1:58890/transfer/runParams", {
          method: "POST",
          body: JSON.stringify(wraper),
        });
        console.log(res);
      }
    },
  },
};
</script>

<style scoped>
li{
  padding: 20px;
}
</style>
